import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

const Archivelist = ({ props }) => {
  const postResults = props.allPosts.filter(
    (a) => a.postType === "post" || a.postType === "curated"
  );
  const pressResults = props.allPosts.filter((a) => a.postType === "press");
  const newsletterResults = props.allPosts.filter(
    (a) => a.postType === "newsletter"
  );

  const [pageNumbersShowing, setPageNumbersShowing] = useState(3);
  const [posts, setPosts] = useState([]);
  const [currentPostPage, setCurrentPostPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let postPageNumbers = [];

  useEffect(() => {
    setPosts(postResults);
    setPostsPerPage(postsPerPage);
    setStartPostPage();
  }, []);

  const indexOfLastPost = currentPostPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    postPageNumbers.push(i);
  }
  const postPaginate = (event, pageNumber) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentPostPage(pageNumber);
  };

  const renderPostLink = (post) => {
    let link = <></>;
    switch (post.postType) {
      case "curated":
      case "newsletter":
        const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
        const linkUrl = post.content.match(linkRx)[2];
        link = (
          <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
            {post.title}
          </Link>
        );
        break;
      default: {
        (() => {
          switch (post.id) {
            case props.postId:
              link = <strong>{post.title}</strong>;
              break;
            default:
              link = <Link to={`/posts/${post.id}`}>{post.title}</Link>;
              break;
          }
        })();
      }
    }
    return link;
  };

  const setStartPostPage = () => {
    if (props.postId) {
      var postPages = postResults.reduce((pageArray, item, index) => {
        const chunkIndex = Math.floor(index / postsPerPage);

        if (!pageArray[chunkIndex]) {
          pageArray[chunkIndex] = []; // start a new chunk
        }

        pageArray[chunkIndex].push(item);

        return pageArray;
      }, []);
      let startPage = 1;
      let iterator = 0;
      postPages.map((chunk) => {
        let chunkNum = chunk.findIndex(
          (item) => parseInt(item.id) === props.postId
        );
        if (chunkNum > -1) {
          startPage = iterator + 1;
        }
        iterator++;
      });
      setCurrentPostPage(startPage);
    }
  };

  const setStartPressPage = () => {
    if (props.postId) {
      var postPages = postResults.reduce((pageArray, item, index) => {
        const chunkIndex = Math.floor(index / postsPerPage);

        if (!pageArray[chunkIndex]) {
          pageArray[chunkIndex] = []; // start a new chunk
        }

        pageArray[chunkIndex].push(item);

        return pageArray;
      }, []);
      let startPage = 1;
      let iterator = 0;
      postPages.map((chunk) => {
        let chunkNum = chunk.findIndex(
          (item) => parseInt(item.id) === props.postId
        );
        if (chunkNum > -1) {
          startPage = iterator + 1;
        }
        iterator++;
      });
      setCurrentPostPage(startPage);
    }
  };

  return (
    <>
      <h4>Posts:</h4>
      <>
        <ol className="list-group">
          {currentPosts.map((post) => (
            <li className="list-group-item" key={post.id}>
              <span className="small text-muted">{post.date}</span>
              <br />
              {renderPostLink(post)}
            </li>
          ))}
        </ol>
        <nav>
          <ul className="pagination pagination-sm flex-wrap mt-3">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">
                page
              </a>
            </li>
            {postPageNumbers.map((number) =>
              number == currentPostPage ? (
                <li key={number} className="page-item active">
                  <a className="page-link">{number}</a>
                </li>
              ) : (
                <li key={number} className="page-item">
                  <a
                    onClick={(e) => postPaginate(e, number)}
                    href="!#"
                    className="page-link"
                  >
                    {number}
                  </a>
                </li>
              )
            )}
          </ul>

          {pressResults.length > 0 && (
            <>
              <h4 className="mt-2">Press:</h4>
              <ol className="list-group">
                {pressResults.map((post, index) => (
                  <li key={post.id} className="list-group-item">
                    <span className="small text-muted">{post.date}</span>
                    <br />
                    {renderPostLink(post)}
                  </li>
                ))}
              </ol>
            </>
          )}
        </nav>
      </>
    </>
  );
};

export default Archivelist;
