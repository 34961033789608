import React, {useEffect} from 'react';
import { Link } from 'gatsby';
import Head from '../components/Head';
import Layout from "../components/Layout";
import Archivelist from '../components/ArchiveList';
import Blog from '../components/Blog';

const AllPosts = ({ pageContext: { allPosts } }) => {

  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");
  }, []);

  //renderPostLink
  const renderPostLink = (linkParams) => {
    let link = <></>;
    switch (linkParams.post.postType) {
      case "curated":
        const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
        const linkUrl = (linkParams.post.content.match(linkRx)[2]);
        link = <Link className={linkParams.class} href={linkUrl} target="_blank" rel="noopener noreferrer">Go to article...</Link>
        break;
      default:
        link = <Link className={linkParams.class} to={`/posts/${linkParams.post.id}`}>Continue reading...</Link>
      }
    return link;
  }

  const formatDate = (post) => {
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    let postDate = new Date(post.date);
    return(postDate.toLocaleDateString("en-US", dateOptions));
  }

  return (
    <Layout>
      <Head pageTitle="EyeDog.US News and Press" title="EyeDog.US News and Press" path="/posts" description="News and press regarding Wayfinding as a Service for Healthcare." imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/Eyedog.US-Digital-Wayfinding.jpg" type="website" datePublished={(new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' +  new Date().getFullYear()} />
      <style dangerouslySetInnerHTML={{__html: `
          body {
              padding-top: 70px;
          }
      `}} />
      <main className="flex-grow-1 bg-light">
        <div className="container">

          <h1 className="text-center mt-0">News &amp; Updates at Eyedog.US</h1>
          <hr className="divider" />

          {/* Blog */}
          <div className="row">
            <div className="col-md-9 pt-3">
    
              <Blog posts={allPosts} postsPerPage={20}/>
              
            </div>
              
            <div className="col-md-3">
                    <div style={{top:"5rem"}}>
              
                      <div className="p-4">
                        <div id="placeholder_postList">
                          <Archivelist props={
                            {
                              allPosts: allPosts,
                              postId: null
                            }
                          }/>
                        </div>
                        <h4 className="fst-italic mt-4">Elsewhere</h4>
                        <ol className="list-unstyled">
                          <li><a href="https://twitter.com/EyedogUS" target="_blank" title="visit our Twitter"><i className="bi bi-twitter"></i> Twitter</a></li>
                          <li><a href="https://www.linkedin.com/company/eyedog-us/about" target="_blank" title="visit our LinkedIn"><i className="bi bi-linkedin"></i> LinkedIn</a></li>
                        </ol>
                      </div>

                    </div>
            </div>
          </div>



        </div>
      </main>
    </Layout>

  );
}

export default AllPosts;
